import 'dayjs/locale/ko'

import { useMediaQuery } from '@mantine/hooks'
import dayjs from 'dayjs'
import _ from 'lodash'
import Image from 'next/image'
import React, { useCallback } from 'react'

import styles from './KDTKlassCard.module.scss'
import { ICourse } from '../types'
import KDTCardPeriodBadge from './KDTCardPeriodBadge'
import { checkApplyPeriod } from '../utils/checkApplyPeriod'

dayjs.locale('ko')

interface IKDTKlassProps {
  course: ICourse
  handleRouter(courseId: string): void
}

const HomeKDTCard = ({ course, handleRouter }: IKDTKlassProps) => {
  const { id, title, subtitle, image, imageM, applyStartedAt, applyEndedAt, tags } = course
  const status = checkApplyPeriod(applyStartedAt, applyEndedAt)
  const startDay =
    status === 'early'
      ? dayjs(applyStartedAt).format('YY.MM')
      : dayjs(applyStartedAt).format('YY.MM.DD')
  const endDay =
    status === 'early'
      ? dayjs(applyEndedAt).format('YY.MM')
      : dayjs(applyEndedAt).format('YY.MM.DD')

  const goCourseDetail = useCallback(() => {
    handleRouter(id)
  }, [course, handleRouter, id])

  const curDate = dayjs()
  const isAfterApplyEnd = dayjs(curDate).isAfter(applyEndedAt)

  const isTablet = useMediaQuery('(max-width: 1023px)')
  const schoolTitleNoNumber = title.replaceAll(/[0-9]/g, '')
  const schoolTitle = schoolTitleNoNumber.replaceAll(/[기]/g, '')

  return (
    <li
      className={`cursor-pointer ${isTablet ? '' : styles.HoverImgScale}`}
      onClick={goCourseDetail}
    >
      <div className="relative flex h-full w-full shrink-0 flex-col rounded-lg">
        {image && (
          <div className="relative aspect-[1.42/1] shrink-0 overflow-hidden rounded-md bg-[#DFDFE0] md:rounded-[8.66px] lg:aspect-[1.32/1] lg:rounded-[7.8px]">
            <Image
              src={isTablet ? imageM : image}
              alt={title}
              fill={true}
              style={{
                objectFit: 'cover',
              }}
              priority={true}
              sizes="100vw"
            />
          </div>
        )}
        <div className="mt-2.5 box-border flex h-full w-full flex-col gap-[7px] md:mt-4 md:gap-1.5 lg:mt-3 lg:gap-2">
          <p className="text-[15px] font-semibold md:text-lg lg:text-xl">{schoolTitle}</p>
          <div className="flex flex-row items-center justify-start gap-1 whitespace-nowrap text-[13px] md:gap-1.5 md:text-base">
            <span className="text-gray-400">모집기간</span>
            <span className="text-gray-500">
              {isAfterApplyEnd ? '미정' : `${startDay} ~ ${endDay}`}
            </span>
          </div>
          {tags && (
            <div className="flex flex-row items-center justify-start gap-1 lg:gap-1.5">
              {tags.map(({ name }, idx) => (
                <span
                  key={`tag_${name}`}
                  className="whitespace-nowrap rounded bg-[#F5F5F5] px-1 py-[1.5px] text-xs text-gray-400 last:truncate md:px-2 md:text-[15px] lg:rounded-md lg:px-1.5 lg:py-0.5 lg:text-sm"
                >
                  {name}
                </span>
              ))}
            </div>
          )}
          <KDTCardPeriodBadge startedAt={applyStartedAt} endedAt={applyEndedAt} />
        </div>
      </div>
    </li>
  )
}
export default HomeKDTCard
