import _ from 'lodash'
import { useCallback } from 'react'

import HomeKDTCard from './HomeKDTCard'
import { ICourse } from '../types'
import { useRouter } from 'next/navigation'

interface IKDTKlassProps {
  course: ICourse
}

const KDTKlassCard = ({ course }: IKDTKlassProps) => {
  const router = useRouter()
  const handleRouter = useCallback(
    (klassId: string) => router.push(`/school/${klassId}`, { scroll: true }),
    [router],
  )
  return <HomeKDTCard course={course} handleRouter={handleRouter} />
}

export default KDTKlassCard
