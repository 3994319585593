import { Modal } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import classNames from 'classnames'

import styles from '../Home.module.scss'

interface IVideoModalProps {
  isModalOpen: boolean
  onClose: () => void
  videoUrl: string
}

const VideoModal = ({ isModalOpen, onClose, videoUrl }: IVideoModalProps) => {
  const isTablet = useMediaQuery('(max-width: 1023px)')
  return (
    <Modal
      opened={isModalOpen}
      centered
      onClose={onClose}
      size={isTablet ? '100%' : '976px'}
      classNames={{
        close: classNames(styles.customCloseButton),
      }}
      styles={{
        close: {
          color: '#fff',
          outline: 'none',
          '&:hover': {
            outline: 'none !important',
            background: 'transparent !important',
          },
          '&:focus': {
            outline: 'none !important',
            background: 'transparent !important',
          },
          '& > svg': {
            width: '24px',
            height: '24px',
          },
          width: '40px',
          height: '40px',
        },
        inner: { padding: isTablet ? '0' : '0 24px' },
        content: { background: 'transparent', boxShadow: 'none' },
        header: { padding: '0', background: 'transparent' },
        body: {
          padding: '0',
          background: 'transparent',
        },
        overlay: {
          backdropFilter: 'blur(2px)',
        },
      }}
    >
      <div className="h-full w-full">
        <div className="bg-black">
          <iframe
            src={videoUrl}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            className="aspect-video w-full"
          ></iframe>
        </div>
      </div>
    </Modal>
  )
}

export default VideoModal
