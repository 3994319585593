import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOfAfter from 'dayjs/plugin/isSameOrAfter'

dayjs.extend(isSameOfAfter)
dayjs.extend(isBetween)

export const checkApplyPeriod = (startedAt: string, endedAt: string): string => {
  const curDate = dayjs()
  const startDay = dayjs(startedAt)
  const endDay = dayjs(endedAt)
  if (curDate.isBefore(startDay)) {
    return 'early'
  }

  if (curDate.isBetween(startDay, endDay)) {
    return 'apply'
  }

  return 'applyEnd'
}
