import Link from 'next/link'

const ViewDetailsButton = ({ title, link }: { title: string; link: string }) => (
  <Link
    href={link}
    className="inline-block cursor-pointer rounded-full bg-gradient-to-r from-yellow-450 to-[#EC64A6] px-6 py-3 lg:px-8 lg:py-[18px]"
  >
    <span className="text-base font-semibold text-white lg:text-xl">{title}</span>
  </Link>
)

export default ViewDetailsButton
