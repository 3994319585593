import React, { useEffect, useRef, useState } from 'react'

interface ICounterProps {
  start: string | number
  end: string | number
  duration: number
}

const Counter = ({ start, end, duration }: ICounterProps) => {
  const startNum = typeof start === 'string' ? parseFloat(start.replace(/,/g, '')) : start
  const endNum = typeof end === 'string' ? parseFloat(end.replace(/,/g, '')) : end

  const [count, setCount] = useState(startNum)
  const [hasStarted, setHasStarted] = useState(false)
  const counterRef = useRef<HTMLSpanElement>(null)

  const decimalPlaces = endNum.toString().includes('.') ? endNum.toString().split('.')[1].length : 0

  const formatOptions =
    decimalPlaces > 0
      ? { minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces }
      : {}

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setHasStarted(true)
          observer.disconnect()
        }
      },
      { threshold: 0.5 },
    )

    if (counterRef.current) {
      observer.observe(counterRef.current)
    }

    return () => observer.disconnect()
  }, [])

  useEffect(() => {
    if (hasStarted) {
      let current = startNum
      const range = endNum - startNum
      const increment = range > 0 ? 1 : -1
      const step = Math.abs(range / (duration / 10))

      const timer = setInterval(() => {
        current += increment * step
        if ((increment > 0 && current >= endNum) || (increment < 0 && current <= endNum)) {
          current = endNum
          clearInterval(timer)
        }
        setCount(parseFloat(current.toFixed(decimalPlaces)))
      }, 10)

      return () => clearInterval(timer)
    }
  }, [hasStarted, startNum, endNum, duration, decimalPlaces])

  return <span ref={counterRef}>{count.toLocaleString(undefined, formatOptions)}</span>
}

export default Counter
